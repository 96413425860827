import React from 'react';
import Button from './Button';

// tags route

const Tags = props => {
  if (!props.tags.length) return null;

  const buttons = props.tags.map(tag => ({
    type: tag.prefix !== '—' ? 'link' : 'category',
    name: tag.name,
    ...(tag.prefix !== '—' && {link: `/tag/${tag.slug}/`}),
    ...(tag.color && {color: tag.color}),
  }));

  return (
    <div className="width height scroll-y scroll-y-nobar">
      <div className="p-h flex flex-wrap">
        {buttons.map(t => (
          <Button key={t.name} tag={t} />
        ))}
      </div>
    </div>
  );
};

export default Tags;
