import React, {useEffect} from 'react';
import {useLocation, Switch} from 'react-router-dom';
import {a, useTransition, useSpringRef} from '@react-spring/web';

import useStore from './useStore';
import {segments} from './utils';

// animated routes
// https://react-spring.io/hooks/use-transition#transitioning-between-routes

const MainRoutes = props => {
  const routes = props.children;
  const location = useLocation();
  const ref = useSpringRef();
  const main = segments(location.pathname);
  const vertical = useStore(s => s.vertical);

  const transition = useTransition(location, {
    key: location => segments(location.pathname),
    ref: ref,
    from: {opacity: 0, [vertical ? 'x' : 'y']: '100%', scale: 0.5},
    enter: {opacity: 1, [vertical ? 'x' : 'y']: '0%', scale: 1},
    leave: {opacity: 0, [vertical ? 'x' : 'y']: '-50%', scale: 0.5},
  });

  useEffect(() => {
    ref.start();
  }, [main]); // eslint-disable-line

  return transition((style, item) => (
    <a.div className="width height absolute hidden will-change" style={style}>
      <Switch location={item}>{routes}</Switch>
    </a.div>
  ));
};

export default MainRoutes;
