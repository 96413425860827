import React, {useEffect, useState} from 'react';
import {useLocation, Redirect} from 'react-router-dom';

import Content from './Content';
import {performRequest} from './fetch';

// page route

const Page = props => {
  const location = useLocation();
  let path = location.pathname.split('/');
  path.splice(0, 2);
  path = '/' + path.join('/');

  const [nothing, setNothing] = useState(false);
  const [state, setState] = useState(null);

  // request page
  useEffect(() => {
    performRequest('get', 'page', {page: path})
      .then(response => {
        const {page} = response.data;

        setState({page: page, current: ''});
      })
      .catch(error => setNothing(true));
  }, []); // eslint-disable-line

  // if request failed, return redirect
  if (nothing) return <Redirect to="/" />;

  if (!state) return null;

  return <Content page={state.page} path={state.page.path} current={state.current} />;
};

export default Page;
