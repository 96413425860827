import React from 'react';

//

const Text = props => {
  return (
    <div className="width height scroll-y scroll-y-nobar">
      <div className="p-1 width-50 width-sm" style={{marginTop: '6em', marginBottom: '6em'}} dangerouslySetInnerHTML={{__html: props.datas.text}} />
    </div>
  );
};

export default Text;
