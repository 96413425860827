export const clamp = (number, min, max) => {
  return Math.min(Math.max(number, min), max);
};

// first url segments only
export const segments = (pathname, num = 3) => {
  let segments = pathname.split('/');
  segments.splice(num, segments.length - num);
  segments = segments.join('/');
  return segments;
};
