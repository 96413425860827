import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga4';
import {useLocation, Switch, Route, Redirect} from 'react-router-dom';
import {injectGlobal} from 'emotion';
import {a, useSpring, easings, to} from '@react-spring/web';

import useStore from './useStore';
import Header from './Header';
import MainRoutes from './MainRoutes';
import Page from './Page';
import Tags from './Tags';
import Tag from './Tag';
import {performRequest} from './fetch';
import './index.css';

//

const App = () => {
  const location = useLocation();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [size, setSize] = useState(0);
  const [meta, setMeta] = useState(null);
  const [ga, setGa] = useState(null);
  const [home, setHome] = useState(null);
  const [tags, setTags] = useState([]);
  const [back, setBack] = useState(false);
  const set = useStore(s => s.set);
  const vertical = useStore(s => s.vertical);
  const gradient = useStore(s => s.gradient);
  const [{cf, cm, ct}, api] = useSpring(() => ({cf: '#000000', cm: '#000000', ct: '#000000', config: {duration: 10000, easing: easings.easeInOutCubic}}));

  useEffect(() => {
    if (gradient.length === 0) return;

    api.start({cf: gradient[0], cm: gradient[1], ct: gradient[2]});
  }, [gradient, api]);

  // resize
  useEffect(() => {
    const resize = () => {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      setWidth(windowWidth);
      setHeight(windowHeight);
      setSize(vertical ? windowHeight : windowWidth);

      // fluid typography: document body rounded font size for more consistent baseline grid
      // https://css-tricks.com/snippets/css/fluid-typography/
      // minFont + (maxFont - minFont) * ((100vw - minWidth) / (maxWidth - minWidth)))
      let fluidTypo = Math.floor(16 + (29 - 16) * ((windowWidth - 320) / (3840 - 320)));
      document.body.style.fontSize = fluidTypo + 'px';
    };

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [vertical]);

  // init
  useEffect(() => {
    performRequest('get', 'initial')
      .then(response => {
        const {meta, font, colors, ga, vertical, arrow_prev, arrow_next, pagination, tag, tags, pinned} = response.data;
        // console.log(response.data);

        // options
        set({vertical, arrow_prev, arrow_next, pagination});

        // GA
        if (ga) {
          ReactGA.initialize(ga);
          ReactGA.send({hitType: 'pageview', page: location.pathname + location.search});
          setGa(ga);
        }

        // meta
        setMeta(
          <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta property="og:site_name" content={meta.title} />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:image" content={process.env.REACT_APP_URL + 'logo.png'} />
            <meta property="og:url" content={process.env.REACT_APP_URL} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={meta.title} />
          </Helmet>
        );
        // styles
        injectGlobal`
          ${font.src}
          body, a, select, input { font-family: ${font.name}; color: ${colors.text_site}; }
          html, #root, input { background-color: ${colors.background_site}; }
          .tag-colored { color: ${colors.text_tags}; background-color: ${colors.background_tags} }
        `;

        // title
        set({title: meta.title});

        // background
        set({
          background: colors.background_site,
          gradient: [colors.background_site, colors.background_site, colors.background_site],
        });

        // set first tag as home
        if (tag) {
          setHome(`/tag/${tag}/random`);
        }

        setTags(tags);

        // pinned tags
        set({pinned});
      })
      .catch(error => console.log(error));
  }, []); // eslint-disable-line

  // ga
  useEffect(() => {
    // set go back status
    if (location.pathname !== '/tags/') setBack(true);
    // GA pageview
    if (ga) ReactGA.send({hitType: 'pageview', page: location.pathname + location.search});
  }, [location.pathname, location.search, ga]);

  //

  return (
    <div className="relative" style={{width, height}}>
      {meta}

      <div className="absolute top left" style={{width, height}}>
        <a.div className="width height" style={{background: to([cf, cm, ct], (cf, cm, ct) => `linear-gradient(to right, ${cf}, ${cm} 42% 58%, ${ct})`)}} />
      </div>

      <Switch>
        <Route
          strict
          path={['/tags/', '/tag/:id/', '/page/:id/']}
          children={
            <MainRoutes>
              <Route strict path="/tags/" children={<Tags tags={tags} back={back} />} />
              <Route strict path="/tag/:id/" children={<Tag size={size} />} />
              <Route strict path="/page/:id/" children={<Page />} />
            </MainRoutes>
          }
        />
        {home && <Route exact strict from="/" render={() => <Redirect to={home} />} />}
        {home && <Route from="/:somethingelse" render={() => <Redirect to={home} />} />}
      </Switch>

      <Header home={home} width={width} tags={tags} />
    </div>
  );
};

export default App;
