import React, {useRef, useEffect} from 'react';

//

// https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API#examples

const Video = props => {
  const ref = useRef();

  // autoplay
  useEffect(() => {
    if (!ref.current) return;
    if (!props.active) ref.current.pause();
    if (props.active) ref.current.play();
  }, [props.active]); // eslint-disable-line

  // exit fullscreen
  useEffect(() => {
    if (!ref.current) return;
    if (props.active) return;
    if (document.fullscreenElement) document.exitFullscreen();
  }, [props.active]);

  return (
    <div className="width height flex flex-align-x-center flex-align-y-center">
      <video ref={ref} src={props.datas.httpUrl} muted autoPlay loop controls className="max-width max-height shadow">
        <source src={props.datas.httpUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
