import {create} from 'zustand';

const useStore = create(set => ({
  title: '',
  codex: 1,
  tags: null,
  pinned: [],
  vertical: true,
  credits: null,
  pagination: false,
  arrow_prev: false,
  arrow_next: false,
  background: null,
  gradient: [],
  set: values => set(s => ({...s, ...values})),
}));

export default useStore;
