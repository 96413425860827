import axios from 'axios';

export const performRequest = (method, url, params) => {
  let body = method === 'get' ? 'params' : 'data';

  const config = {
    method: method,
    baseURL: process.env.REACT_APP_API_URL,
    url: url,
    [body]: params,
  };

  return axios.request(config);
};
