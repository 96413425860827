import React, {useCallback, useRef, useEffect, useState, useMemo} from 'react';
import {useParams, useHistory, Redirect} from 'react-router-dom';
import {a, useSpring, useSprings, config} from '@react-spring/web';
import {useDrag, useWheel} from '@use-gesture/react';

import Button from './Button';
import Content from './Content';
import useKeyPress from './useKeyPress';
import useStore from './useStore';
import {clamp} from './utils';

//

// check if url param is not a number or out of bounds, or random
const getIndex = (param, children) => {
  if (param === 'random') return Math.floor(Math.random() * children);
  if (isNaN(param) || param < 0 || param > children) return -1;
  return param - 1;
};

const Slide = props => {
  const history = useHistory();
  const params = useParams();
  const [rested, setRested] = useState(true);
  const active = useRef(false);
  const index = useRef(getIndex(params.id, props.pages.length));
  const wheelable = useRef(true);
  const set = useStore(s => s.set);
  const vertical = useStore(s => s.vertical);
  const credits = useStore(s => s.credits);
  const arrow_prev = useStore(s => s.arrow_prev);
  const arrow_next = useStore(s => s.arrow_next);
  const pagination = useStore(s => s.pagination);
  const [infos, setInfos] = useState(false);
  const ref = useRef();

  const style = useSpring({height: infos ? ref.current?.getBoundingClientRect().height || 0 : 0});

  // if the current page has credits, set them
  useEffect(() => {
    const credits = props.pages[index.current].datas.credits;
    set({credits: credits || null});
  }, [params.id, index.current]); // eslint-disable-line

  const nav = useCallback(
    (num, clamped = false) => {
      if (clamped) {
        if (num < 0 && index.current + num < 0) return;
        if (num > 0 && index.current + num > props.pages.length - 1) return;
      }
      // update url
      // https://stackoverflow.com/a/42121109/7662622
      const current = (props.pages.length + index.current + num) % props.pages.length;
      history.push(props.path + (current + 1));
    },
    [history, props.pages.length, props.path]
  );

  const arrow1 = useMemo(() => ({type: 'arrow', name: 'prev', vertical, callback: () => nav(-1)}), [nav, vertical]);
  const arrow2 = useMemo(() => ({type: 'arrow', name: 'next', vertical, callback: () => nav(1)}), [nav, vertical]);

  // arrow keys nav
  const arrowPrev = useKeyPress(vertical ? 'ArrowUp' : 'ArrowLeft');
  const arrowNext = useKeyPress(vertical ? 'ArrowDown' : 'ArrowRight');
  useEffect(() => {
    if (props.pages.length <= 0) return;
    if (arrowPrev) nav(-1, true);
    if (arrowNext) nav(1, true);
  }, [props.pages.length, arrowPrev, arrowNext, nav]);

  const [springs, setSprings] = useSprings(props.pages.length, i => ({
    from: {
      [vertical ? 'y' : 'x']: i * props.size - index.current * props.size,
      scale: 1,
      display: 'none',
      config: config.slow,
    },
    onStart: () => {
      if (i === index.current) {
        setRested(false);
        active.current = true;
      }
    },
    onRest: () => {
      if (i === index.current) {
        setRested(true);
        active.current = false;
      }
    },
  }));

  const springsChange = (size, index, delta, scale) => i => {
    const p = (i - index) * size + delta;
    if (i < index - 1 || i > index + 1) return {[vertical ? 'y' : 'x']: p, scale, display: 'none'};
    return {[vertical ? 'y' : 'x']: p, scale, display: 'block'};
  };

  // update spring according to url param
  useEffect(() => {
    if (isNaN(params.id)) return;
    if (index.current === -1) return;

    index.current = clamp(params.id - 1, 0, props.pages.length - 1);

    const setSpring = springsChange(props.size, index.current, 0, 1);
    setSprings.start(setSpring);
  }, [params.id]); // eslint-disable-line

  // scroll nav
  const wheel = useWheel(({delta: [dx, dy], memo: [deltaPrev, acceleratingPrev] = [0, false], direction: [xDir, yDir]}) => {
    const delta = vertical ? dy : dx;
    const direction = vertical ? yDir : xDir;
    const accelerating = Math.abs(delta) > Math.abs(deltaPrev) && Math.abs(delta) - Math.abs(deltaPrev) > 0;
    const idle = deltaPrev === 0;
    const uturn = Math.sign(delta) !== Math.sign(deltaPrev);
    const spamming = !active.current && accelerating;
    const ready = idle || uturn || spamming;

    if (!wheelable.current && ready && !active.current) wheelable.current = true;

    const wheel = (acceleratingPrev || deltaPrev === 0 || delta === 0) && accelerating;
    if (wheelable.current && wheel) {
      wheelable.current = false;
      // nav(direction, true);
      nav(direction, direction === 1 ? false : true);
    }

    return [delta, accelerating];
  });

  // gesture based nav
  // https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/viewpager
  const drag = useDrag(({active, movement: [mx, my], direction: [xDir, yDir], cancel}) => {
    const movement = vertical ? my : mx;
    const direction = vertical ? yDir : xDir;
    const distance = Math.abs(movement);
    const scale = 1 - (distance / props.size) * 0.8;

    // offset limit to swipe event
    const limit = props.size * 0.15;
    if (active && distance > limit) {
      cancel();
      nav(direction * -1, direction === 1 ? true : false);
    }

    const setSpring = springsChange(props.size, index.current, active ? movement : 0, active ? scale : 1);
    setSprings.start(setSpring);
  });

  // return redirect if index is invalid
  if (index.current === -1) return <Redirect to={props.path} />;

  return (
    <div {...wheel()}>
      {springs.map(({x, y, scale, display}, i) => (
        <a.div key={i} {...drag()} style={{display: rested ? (i === index.current ? 'block' : 'none') : display, x, y, touchAction: 'none'}} className="absolute hidden width height will-change">
          <a.div style={{scale}} className="width height will-change">
            <Content page={props.pages[i]} path={props.pages[index.current].path} current={props.tag} />
          </a.div>
        </a.div>
      ))}

      {(!!props.credits || !!credits) && (
        <div className="fixed bottom left">
          <div className="p-h flex">
            <Button tag={{type: 'infos', open: infos, callback: () => setInfos(!infos)}} />
          </div>
          <a.div className="hidden" style={style}>
            <div ref={ref} className="pl-h pb-h">
              <div className="pl-w pb-w t-shadow" dangerouslySetInnerHTML={{__html: props.credits || credits}} />
            </div>
          </a.div>
        </div>
      )}

      {!!pagination && !infos && props.pages.length > 1 && (
        <div className="fixed bottom center p-h">
          <div className="p-w">
            <span className="pt-q pb-q pl-h pr-h t-nowrap t-shadow">{`${index.current + 1} / ${props.pages.length}`}</span>
          </div>
        </div>
      )}

      {(!!arrow_prev || !!arrow_next) && (
        <div className="fixed bottom right p-h flex flex-wrap flex-align-x-right">
          {!!arrow_prev && props.pages.length > 1 && <Button tag={arrow1} />}
          {!!arrow_next && props.pages.length > 1 && <Button tag={arrow2} />}
        </div>
      )}
    </div>
  );
};

export default Slide;
