import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';

console.log('made by https://subtext.studio/');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
    <App id="1" />
  </BrowserRouter>
);
