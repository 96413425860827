import React, {useState, useRef, useEffect, useMemo} from 'react';
import {useTransition, config} from '@react-spring/web';
import {LuMenu, LuX} from 'react-icons/lu';

import useStore from './useStore';
import Button from './Button';

//

const modes = ['focus', 'current', 'all'];
export const icons = [LuMenu, LuMenu, LuX];

const Header = props => {
  const elements = useRef({});
  const sizes = useRef(null);
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef();
  const title = 'hamburger';
  const codex = useStore(s => s.codex);
  const width = props.width;
  const tags = props.tags;
  const current = useStore(s => s.tags);

  // all tags
  const all = useMemo(() => {
    // return [
    //   ...[{type: 'menu', name: title}],
    //   ...Array(150)
    //     .fill()
    //     .map((_, i) => ({type: 'disabled', name: `item ${i}`})),
    // ];
    return tags.reduce(
      (acc, val) => {
        return [
          ...acc,
          ...[
            {
              type: val.prefix !== '—' ? 'link' : 'category',
              name: val.name,
              ...(val.prefix !== '—' && {link: `/tag/${val.slug}/`}),
              ...(val.color && {color: val.color}),
              ...(val.color_text && {color_text: val.color_text}),
            },
          ],
        ];
      },
      [{type: 'menu', name: 'main'}]
    );
  }, [tags]);

  // tags to show (according to current mode)
  const items = useMemo(() => {
    let items = [];

    if (modes[codex] === 'focus') items = [{type: 'menu', name: 'main'}];
    if (modes[codex] === 'current') items = current ?? [];
    // if (modes[codex] === 'current') items = all ?? [];
    if (modes[codex] === 'all') items = all ?? [];

    return items;
  }, [codex, title, all, current]); // eslint-disable-line

  // compute tags positions
  const animated = useMemo(() => {
    if (!sizes.current || !ready) return [];

    return items.reduce((acc, val, idx) => {
      const s = sizes.current.find(o => o.name === val.name);
      const w = s?.w || 0;
      const h = s?.h || 0;
      const prev = idx > 0 && acc[idx - 1];
      const xx = prev?.xx || 0;
      const yy = prev?.yy || 0;
      const nl = xx + w >= width - 16;
      const x = nl ? 0 : xx;
      const y = nl ? yy + h : yy;

      return [...acc, {...val, x, y, w, h, xx: x + w, yy: y}];
    }, []);
  }, [ready, items, width]);

  // set container height
  useEffect(() => {
    if (!animated.length) return;

    const last = animated[animated.length - 1];
    const next = last.yy + last.h;

    const timer = setTimeout(() => setHeight(next), next < height ? 2000 : 0);

    return () => clearTimeout(timer);
  }, [animated, height]);

  // reset scroll
  useEffect(() => {
    const timer = setTimeout(() => {
      ref.current?.scrollTo({top: 0, behavior: 'smooth'});
    }, 500);

    return () => clearTimeout(timer);
  }, [items]);

  // reset tags sizes if window width has changed
  useEffect(() => {
    setReady(false);
  }, [props.width]);

  // get all tags sizes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!tags.length || ready) return;

      sizes.current = all.map(o => {
        const b = elements.current[o.name]?.getBoundingClientRect();
        return {name: o.name, w: b?.width, h: b?.height};
      }, []);

      setReady(true);
    }, 250);

    return () => clearTimeout(timer);
  }, [tags, ready, all]);

  const transitions = useTransition(animated, {
    from: item => ({transform: `translate3d(${item.x}px,${item.y - item.h}px,0)`, opacity: 0}),
    enter: item => ({transform: `translate3d(${item.x}px,${item.y}px,0)`, opacity: 1}),
    leave: item => ({transform: `translate3d(${item.x}px,${item.y - item.h}px,0)`, opacity: 0}),
    trail: 10,
    config: config.stiff,
  });

  return (
    <div ref={ref} className="absolute top left width max-height scroll-y scroll-y-nobar">
      {!ready ? (
        <div className="p-h flex flex-wrap" style={{opacity: 0}}>
          {all.map(tag => (
            <Button key={tag.name} ref={el => (elements.current[tag.name] = el)} tag={tag} />
          ))}
        </div>
      ) : (
        <div className="p-h">
          <div className="flex flex-wrap" style={{height}}>
            {transitions((style, tag) => (
              <Button style={{...style, position: 'absolute', willChange: 'transform, opacity'}} tag={tag} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
