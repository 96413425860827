import React, {useEffect, useState} from 'react';
import {css} from 'emotion';

//

const Loading = () => {
  const [spinners, setSpinners] = useState([]);

  useEffect(() => {
    setSpinners([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => <div key={i} className={`blade blade-${i}`} />));
  }, []);

  return (
    <div
      className={css`
        font-size: 1.5em;
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
      `}
    >
      {spinners}
    </div>
  );
};

export default Loading;
