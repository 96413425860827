import React, {useEffect, useState, useRef} from 'react';
import {a} from '@react-spring/web';

import Loading from './Loading';

//

const Image = props => {
  const [shown, setShown] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    // show the image
    if (props.active && !shown) setShown(true);
  }, [props.active]); // eslint-disable-line

  const load = () => {
    if (!loaded) setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) load();
  }, [ref.current]); // eslint-disable-line

  return (
    <>
      {!loaded && (
        <div className="width height flex flex-align-x-center flex-align-y-center">
          <Loading />
        </div>
      )}
      <a.div className={['width height', !props.datas.full && 'flex flex-align-x-center flex-align-y-center', loaded && 'appear'].filter(Boolean).join(' ')}>
        {shown && (
          <img
            ref={ref}
            onLoad={load}
            draggable={false}
            src={props.datas.httpUrl}
            alt=""
            className={['max-width max-height noselect', props.datas.full && 'width height cover', props.datas.shadow && 'shadow'].filter(Boolean).join(' ')}
            style={props.datas.full ? {objectPosition: `${props.datas.focus.left}%${props.datas.focus.top}%`} : null}
          />
        )}
      </a.div>
    </>
  );
};

export default Image;
