import React, {useEffect, useState} from 'react';
import {useParams, useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';

import Slide from './Slide';
import {performRequest} from './fetch';
import useStore from './useStore';

// tag route

const Tag = props => {
  const params = useParams();
  const match = useRouteMatch();
  const [nothing, setNothing] = useState(false);
  const [state, setState] = useState(null);
  const set = useStore(s => s.set);
  const background = useStore(s => s.background);
  const tag = params.id;

  // request tag
  useEffect(() => {
    performRequest('get', 'tag', {tag})
      .then(response => {
        const {pages, tag, credits, gradient} = response.data;

        setState({pages, tag, credits});

        set({gradient: !gradient ? [background, background, background] : gradient.split(' ')});
      })
      .catch(error => setNothing(true));
  }, []); // eslint-disable-line

  // if request failed, return redirect
  if (nothing)
    return (
      <Switch>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );

  // if no url param, redirect with one
  if (match.isExact)
    return (
      <Switch>
        <Route render={() => <Redirect to={`${match.url}random`} />} />
      </Switch>
    );

  // no pages, no route
  let route = null;
  if (state?.pages?.length) {
    const tagPath = match.url;
    const size = props.size;
    route = (
      <Switch>
        <Route path={`${match.url}:id`} children={<Slide key={state.current} path={tagPath} pages={state.pages} tag={state.tag} color={state.color} credits={state.credits} size={size} />} />
      </Switch>
    );
  }

  return <div className="width height relative hidden">{route}</div>;
};

export default Tag;
