import React, {forwardRef} from 'react';
import {a} from '@react-spring/web';
import {useHistory} from 'react-router-dom';
import {BsQuestionLg} from 'react-icons/bs';
import {LuArrowUp, LuArrowDown, LuArrowLeft, LuArrowRight, LuX} from 'react-icons/lu';

import {icons} from './Header';
import useStore from './useStore';

const Button = forwardRef((props, ref) => {
  const {style = null, tag} = props;
  const set = useStore(s => s.set);
  const codex = useStore(s => s.codex);
  const history = useHistory();

  let elm;
  switch (tag.type) {
    case 'menu':
      const IconMenu = icons[codex];
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center pointer shine"
          tabIndex={1}
          onClick={() => set({codex: (codex + 1) % 3})}
          onKeyUp={e => e.key === 'Enter' && set({codex: (codex + 1) % 3})}
        >
          <IconMenu />
        </span>
      );
      break;
    case 'callback':
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center pointer shine"
          tabIndex={1}
          onClick={tag.callback}
          onKeyUp={e => e.key === 'Enter' && tag.callback()}
        >
          {tag.name}
        </span>
      );
      break;
    case 'disabled':
      elm = <span className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center disabled">{tag.name}</span>;
      break;
    case 'category':
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center disabled"
          style={{...(tag.color && {background: tag.color}), ...(tag.color_text && {color: tag.color_text})}}
        >
          {tag.name}
        </span>
      );
      break;
    case 'link':
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center pointer shine"
          style={{...(tag.color && {background: tag.color}), ...(tag.color_text && {color: tag.color_text})}}
          tabIndex={1}
          onClick={() => history.push(`${tag.link}1`)}
          onKeyUp={e => e.key === 'Enter' && history.push(`${tag.link}1`)}
        >
          {tag.name}
        </span>
      );
      break;
    case 'infos':
      const IconInfos = tag.open ? LuX : BsQuestionLg;
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center pointer shine"
          tabIndex={3}
          onClick={tag.callback}
          onKeyUp={e => e.key === 'Enter' && tag.callback()}
        >
          <IconInfos />
        </span>
      );
      break;
    case 'arrow':
      const IconPrev = tag.vertical ? LuArrowUp : LuArrowLeft;
      const IconNext = tag.vertical ? LuArrowDown : LuArrowRight;
      elm = (
        <span
          className="tag tag-colored pt-q pb-q pl-h pr-h t-nowrap radius flex flex-align-y-center pointer shine"
          tabIndex={3}
          onClick={tag.callback}
          onKeyUp={e => e.key === 'Enter' && tag.callback()}
        >
          {tag.name === 'prev' && <IconPrev />}
          {tag.name === 'next' && <IconNext />}
        </span>
      );
      break;
    default:
      elm = null;
  }

  return (
    <a.div key={tag.name} ref={ref} className="p-w" style={style}>
      {elm}
    </a.div>
  );
});

export default Button;
