import React, {useEffect} from 'react';

import useStore from './useStore';
import Text from './Text';
import Image from './Image';
import Video from './Video';

//

const Content = props => {
  const set = useStore(s => s.set);
  const pinned = useStore(s => s.pinned);
  const codex = useStore(s => s.codex);
  const page = props.page;
  const active = props.path === page.path;

  useEffect(() => {
    if (!active) return;

    // show current tag before others
    const current = props.current;
    let tags = [...page.tags].sort(tag => (tag.name === current ? -1 : 1));

    // prepend pinned tags
    const excepted = pinned.map(o => o.name);
    tags = [...pinned, ...tags.filter(o => !excepted.includes(o.name))];

    // format buttons objects
    tags = tags.map(tag => ({
      type: current === tag.name ? 'disabled' : tag.prefix !== '—' ? 'link' : 'category',
      name: tag.name,
      ...(tag.prefix !== '—' && {link: `/tag/${tag.slug}/`}),
      ...(tag.color && {color: tag.color}),
      ...(tag.color_text && {color_text: tag.color_text}),
    }));

    // if showing all tags, change mode back to current
    set({codex: codex === 2 ? 1 : codex, tags: [{type: 'menu', name: 'main'}, ...tags]});
  }, [active]); // eslint-disable-line

  // get content
  let content = null;
  if (!!page.datas && page.type === 'text') content = <Text datas={page.datas} />;
  if (!!page.datas && page.type === 'image') content = <Image datas={page.datas} active={active} />;
  if (!!page.datas && page.type === 'video') content = <Video datas={page.datas} active={active} />;

  return content;
};

export default Content;
